/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const Cookies = require('react-cookie').Cookies;

// You can delete this file if you're not using it
const React = require('react');
const GlobalContextProvider = require('./src/context/GlobalContextProvider').default;
const GoogleReCaptchaProvider = require('react-google-recaptcha-v3').GoogleReCaptchaProvider;

exports.disableCorePrefetching = () => true;

const cookies = new Cookies();

const activateOptimize = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: "optimize.activate" });
};

exports.onClientEntry = async () => {
    const _gid = cookies.get('_gid');
    if(_gid) {
        const cookieParts = _gid.split('.');
        const GAID = `${cookieParts[cookieParts.length - 2]}.${cookieParts[cookieParts.length - 1]}`;
        cookies.set('GAID', GAID);
    }

    if (typeof IntersectionObserver === "undefined") {
        await require("intersection-observer");
        console.log("IntersectionObserver polyfilled ;)");
    }
};

exports.wrapRootElement = ({ element }) => {
    return <GlobalContextProvider>
                {element}
            </GlobalContextProvider>;
};

exports.onRouteUpdate = () => {
    if(typeof window !== 'undefined' && window.drift) {
        window.drift.page();
    }

    activateOptimize();
};
