export const IS_MOBILE = typeof window !== 'undefined' && document.documentElement.clientWidth < 767;

export const IS_TABLET = typeof window !== 'undefined' && document.documentElement.clientWidth > 767 && document.documentElement.clientWidth < 1025;

export const IS_DESKTOP = typeof window !== 'undefined' && document.documentElement.clientWidth > 1025;

export const IS_SMALL_DESKTOP = typeof window !== 'undefined' && document.documentElement.clientWidth < 1500 && document.documentElement.clientWidth > 1025;

export const HEADER_Z_INDEX = '9999';

export const HEADER_HEIGHT = 118;

export const HEADER_HEIGHT_DESKTOP_SCROLL_HEIGHT = 40;

export const HEADER_HEIGHT_MOBILE = 72;

export const CURR_HEADER = IS_MOBILE ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT_DESKTOP_SCROLL_HEIGHT;

export const CONTACT_FORM_ID = 2069;

export const CONTACT_FORM_ID_JAPANESE = 2567;

export const CONTACT_FORM_ID_FRENCH = 3465;

export const CONTACT_FORM_ID_GERMAN = 3489;

export const DEMO_FORM_ID = 2073;

export const DEMO_FORM_ID_FRENCH = 3467;

export const DEMO_FORM_ID_GERMAN = 3501;

export const PARTNER_FORM_ID = 2065;

export const PARTNER_FORM_ID_FRENCH = 3471;

export const PARTNER_FORM_ID_GERMAN = 3507;

export const EMAIL_FORM_ID = 1020;

export const WEBINAR_FORM_ID = 2162;

export const ASSET_FORM_ID = 2160;

export const LP_DEMO_FORM_ID = 2452;

export const LP_TEMP_DEMO_ID  = 2578;

export const WEBINAR_TEMP_ID =  2577;

export const DEMO_STAGING_TEST = 2655;

export const WEBINAR_FORM_JP = 3081;

export const LP_DEMO_FORM_JP = 3085;

export const ASSET_FORM_CN = 3415;

export const ASSET_FORM_JP = 3083;

export const EXPERT_PAGE_FORM_ID = 3216;

export const EXPERT_ITALIAN = 3346;

export const EXPERT_FRENCH = 3348;

export const EXPERT_DUTCH = 3353;

export const EXPERT_GERMAN = 3354;

export const EXPERT_JAPANESE = 3400;

export const EXPERT_LEVEL_2 = 3703;

export const GTag = ({category, action, label, value='', nonInteraction=false}) => {
        return (typeof window === 'undefined' || window.gtag === undefined) ? null :
            window.gtag('event', action, {event_category: category, event_label: label, value, non_interaction: nonInteraction})
};

export const ENV_MODE = {
    STAGE: "stage",
    PROD: "prod",
    PREV: "preview"
};

export const LANG = {
    ENG: "EN",
    JP: "JP",
    CN: "CN",
    KR: "KR",
    FR: "FR",
    DE: "DE"
}

export const RESOURCE_FORM_IDS = {
    [LANG.ENG]: {
        webinar: WEBINAR_FORM_ID,
        asset: ASSET_FORM_ID,
        demo: LP_DEMO_FORM_ID
    },
    [LANG.JP]: {
        webinar: WEBINAR_FORM_JP,
        asset: ASSET_FORM_JP,
        demo: LP_DEMO_FORM_JP
    },
    [LANG.CN]: {
        webinar: ASSET_FORM_CN,
        asset: ASSET_FORM_CN,
        demo: ASSET_FORM_CN
    }
}

export const CONTACT_FORM_IDS = {
    [LANG.ENG]: CONTACT_FORM_ID,
    [LANG.JP]: CONTACT_FORM_ID_JAPANESE,
    [LANG.FR]: CONTACT_FORM_ID_FRENCH,
    [LANG.DE]: CONTACT_FORM_ID_GERMAN
}

export const DEMO_FORM_IDS = {
    [LANG.ENG]: DEMO_FORM_ID,
    [LANG.JP]: CONTACT_FORM_ID_JAPANESE,
    [LANG.FR]: DEMO_FORM_ID_FRENCH,
    [LANG.DE]: DEMO_FORM_ID_GERMAN
}

export const CONTACT_MAP = {
    [LANG.ENG]: {
        label: 'CONTACT US',
        path: '/contact-us/'
    },
    [LANG.JP]: {
        label: 'お問い合わせ',
        path: '/jp/contact-us/'
    },
    [LANG.FR]: {
        label: 'Contact',
        path: '/fr/contact-us/'
    },
    [LANG.DE]: {
        label: 'Kontakt',
        path: '/de/contact-us/'
    }
};

export const DEMO_MAP = {
    [LANG.ENG]: {
        label: 'Request a Demo',
        path: '/try/'
    },
    [LANG.JP]: {
        label: 'Request a Demo',
        path: '/jp/try/'
    },
    [LANG.FR]: {
        label: 'Demande De Démo',
        path: '/fr/try/'
    },
    [LANG.DE]: {
        label: 'Demo anfordem',
        path: '/de/try/'
    }
}

export const LANG_PATH_PREFIX = {
    [LANG.ENG]: '/',
    [LANG.JP]: '/jp/',
    [LANG.FR]: '/fr/',
    [LANG.DE]: '/de/'
};

export const RESOURCES_TYPE_TRANSLATE = {
    [LANG.ENG]: {
        webinars: 'Webinars',
        eBooks: 'eBooks',
        demos: 'Demos',
        whitePapers: 'White Papers'
    },
    [LANG.JP]: {
        webinars: 'ウェビナー',
        eBooks: '電子ブック',
        demos: 'デモ',
        whitePapers: 'ホワイトペーパー',

    }
}

export const PARTNERS_LOBBY_FORM_IDS = {
    [LANG.ENG]: PARTNER_FORM_ID,
    [LANG.FR]: PARTNER_FORM_ID_FRENCH,
    [LANG.DE]: PARTNER_FORM_ID_GERMAN
}

export const ACTION_TYPES = {
    TOGGLE_HEADER_FIXED: 'TOGGLE_HEADER_FIXED',
    INIT_HEADER: 'INIT_HEADER',
    TOGGLE_HEADER_VIDEO: 'TOGGLE_HEADER_VIDEO',
    CHOOSE_HEADER_VIDEO: 'CHOOSE_HEADER_VIDEO'
};

export const CURR_VID_INIT = {
    vidId: null,
    title: null,
    subTitle: null,
    description: null,
    path: null,
    buttonLabel: null
}

export const ENTITIES_MAP = {
    '&amp;': '&' ,
'&lt;':'<',
    '&gt;':'>',
    '&quot;':'"',
    '&#x27;':"'",
    '&#x60;':'`',
    '&#039;': "'"
}

export const HOMEPAGE_TRANSLATIONS_DATA = [
    {
        path: '/',
        language: {
            slug: 'en'
        }
    },
    {
        path: '/de/',
        language: {
            slug: 'de'
        }
    },
    {
        path: '/jp/',
        language: {
            slug: 'jp'
        }
    },
    {
        path: '/fr/',
        language: {
            slug: 'fr'
        }
    }
]
