import React, {useReducer} from 'react';
import {ACTION_TYPES, CURR_VID_INIT} from "../common/consts";

export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

const initState =  {
    headerFixed: false,
    headerVideoActive: false,
    headerVideo: {
        ...CURR_VID_INIT
    }
};

function reducer(state, action) {
    switch(action.type) {
        case ACTION_TYPES.TOGGLE_HEADER_FIXED: {
            return {
                ...state,
                headerFixed: !state.headerFixed
            };
        }
        case ACTION_TYPES.INIT_HEADER: {
            return {
                ...state,
                headerFixed: false
            }
        }
        case ACTION_TYPES.TOGGLE_HEADER_VIDEO: {
            const headerVideoActive = state.headerVideoActive;

            return {
                ...state,
                headerVideoActive: !headerVideoActive
            }
        }
        case ACTION_TYPES.CHOOSE_HEADER_VIDEO: {
            const {payload} = action;

            return {
                ...state,
                headerVideo: {...payload}
            }
        }
        default:
            return state;
    }
}

const GlobalContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initState);

    return (
        <GlobalStateContext.Provider value={state}>
            <GlobalDispatchContext.Provider value={dispatch}>
                {children}
            </GlobalDispatchContext.Provider>
        </GlobalStateContext.Provider>
    )
}

export default GlobalContextProvider;