module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cato Networks","short_name":"Cato Networks","start_url":"/","background_color":"#158864","theme_color":"#158864","display":"minimal-ui","icon":"src/assets/images/cato-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"80d6c80624e17e4dcaabea4cf519e067"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-63909158-1"],"gtagConfig":{"optimize_id":"GTM-T7XS4NC","anonymize_ip":true,"cookie_expires":0,"send_page_view":true},"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://catonewwebsite.kinsta.cloud/graphql","presets":[{"presetName":"PREVIEW_OPTIMIZATION","options":{"html":{"useGatsbyImage":false,"createStaticFiles":false,"imageMaxWidth":null,"fallbackImageMaxWidth":100,"imageQuality":90},"type":{"__all":{"limit":null},"Page":{"limit":null},"Post":{"limit":null},"Comment":{"limit":0},"Menu":{"limit":null},"MenuItem":{"limit":null},"User":{"limit":null},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true}}],"type":{"__all":{"limit":null},"MediaItem":{"localFile":{"requestConcurrency":500,"excludeByMimeTypes":[],"maxFileSizeBytes":15728640},"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"debug":{"preview":true,"graphql":{"showQueryVarsOnError":true,"showQueryOnError":false,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"throwRefetchErrors":true,"timeBuildSteps":false,"disableCompatibilityCheck":false},"schema":{"perPage":50,"previewRequestConcurrency":2,"requestConcurrency":50,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000},"html":{"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":100,"imageQuality":90,"createStaticFiles":true},"auth":{"htaccess":{"username":"decor","password":"Tko665dec@@"}},"production":{"allow404Images":true,"hardCacheMediaFiles":false},"verbose":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
